<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfigRef"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      @handleNewClick="handleSubmitClick"
      :isResetFields="false"
    >
      <template #otherFooter>
        <!-- <el-button
          icon="el-icon-edit"
          @click="handleUpdateDashBoard"
          plain
          size="small"
        >
          {{ t('user.update-dashboard-profile') }}
        </el-button> -->
        <el-button
          icon="el-icon-edit"
          @click="handleResetPassword"
          size="small"
          >{{ $t('general.router-reset-password') }}</el-button
        >
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/user.edit'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import i18n from '@/i18n'
import { findCurrentSelectedTreeNode } from '@/utils/util.js'
import _ from 'lodash' // 导入loadsh插件
import { getParamsOptions } from '@/utils/common'
import { getItem } from '@/utils/storage'
import { ElMessage, ElMessageBox } from 'element-plus'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-user-edit')

const routes = useRoute()
const router = useRouter()

const id = routes.params.id
const store = useStore()
const pageInfo = ref({})
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const baseFormConfigRef = ref(baseFormConfig)
const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref()

const isShowTenant = (roleIds) => {
  const tenantItem = baseFormConfigRef.value.formItems.find(
    (item) => item.field === 'tenantId'
  )
  const roleLists = getItem('roleList') || []
  const roleType = []
  if (roleIds && roleIds.length) {
    roleIds.map((id) => {
      roleLists.map((item) => {
        if (item.id === id) {
          roleType.push(item.type)
        }
      })
    })
    tenantItem.isShow = !roleType.some((item) => item === 0)
  }
  if (!tenantItem.isShow) {
    baseFormRef.value.formData.tenantId = ''
  }
}

const handleChange = async (form) => {
  const { fieldName } = form
  if (fieldName === 'roleId') {
    isShowTenant(form.roleId)
  }
}

onMounted(async () => {
  getCurrentPageInfo()
  bus.on('linkageOptionsChange', handleChange)
})

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})

const tenantList = getParamsOptions('tenantList')

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值

  const userInfo = await store.dispatch('user/getUserDetailById', { id })

  if (JSON.stringify(userInfo) !== '{}') {
    userInfo.roleId = userInfo.roleId ? userInfo.roleId.split(',') : []
    isShowTenant(userInfo.roleId)

    userInfo.tenantId = userInfo.tenantId
      ? findCurrentSelectedTreeNode(tenantList, userInfo.tenantId, 'id')
      : ''
    console.log(userInfo.tenantId)
  }
  pageInfo.value = userInfo
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  form.id = id
  form.roleId = form.roleId && form.roleId.length ? form.roleId.join(',') : ''
  form.tenantId = form.tenantId
    ? findCurrentSelectedTreeNode(tenantList, form.tenantId, 'name')
    : ''
  handleMessageCommit('user/handleEditUserAction', form).then(() => {
    router.push('/user/overview')
  })
}

const handleResetPassword = () => {
  ElMessageBox.confirm(
    t('popup.confirm-reset-password'),
    t('general.router-reset-password'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      const res = await store.dispatch('user/handleResetPassword', {
        id
      })

      if (res?.data?.resultCode === 'SUCCESS' || res?.data?.code === 200) {
        ElMessage({
          showClose: true,
          duration: 0, // 设置不自动关闭
          message: res?.data?.msg || '',
          type: 'success'
        })
        router.go(-1)
      } else {
        ElMessage.error(
          (res?.data?.msg && t(res?.data?.msg)) || t('popup.operation-failed')
        )
      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: t('popup.cancel-tip')
      })
    })
}
</script>
